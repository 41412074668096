<template>
  <v-container>
    <v-row class="mb-5">
      <v-col>
        <h3>Allocate Customer Payment</h3>
      </v-col>
    </v-row>
    <!--Payment amount summaries-->
    <CustomerPaymentTotals
      :transaction="transaction"
      :totalAmountAllocated="totalAmountAllocated"
      :amountLeftToAllocate="amountLeftToAllocate"
      :overpaidAmount="overpaidAmount"
      @removeOverpaid="overpaidAmount = 0"
      :type="tab === 0 ? 'Invoices' : 'Pro-Formas'"
    />
    <!--FORM-->
    <v-form ref="form">
      <v-row>
        <!--TABS-->
        <v-tabs v-model="tab">
          <v-tab>Invoices</v-tab>
          <v-tab>Pro-Formas</v-tab>
          <!--Tab Items-->
          <v-tab-item>
            <CustomerPaymentsInvoiceTable
              ref="CustomerPaymentsInvoiceTable"
              :customer="customer"
              :transaction="transaction"
              :amountLeftToAllocate="amountLeftToAllocate"
              @updateSelection="updateSelection($event, 'invoice')"
            />
          </v-tab-item>
          <v-tab-item>
            <CustomerPaymentsProFormaTable
              ref="CustomerPaymentsProFormaTable"
              :customer="customer"
              :transaction="transaction"
              :amountLeftToAllocate="amountLeftToAllocate"
              @updateSelection="updateSelection($event, 'order')"
            />
          </v-tab-item>
        </v-tabs>
      </v-row>
      <v-row> </v-row>
      <v-row>
        <!--Back button-->
        <AllocationBackBtn @passBack="$emit('passBack', $event)" />
        <!--Action Button-->
        <v-btn
          :disabled="returnDisabled"
          :loading="loading"
          color="primary"
          @click="allocateCustomerPayment()"
          >Allocate to {{ returnButtonText }}
        </v-btn>
        <!--Overpaid Button-->
        <v-btn
          text
          color="info"
          @click="$set(overpaidDialog, tab, true)"
          v-if="triggerOverpaid === true"
          >Allocate Remainder As Overpaid</v-btn
        >
      </v-row>
    </v-form>
    <OverpaidModal
      :overpaidDialog="overpaidDialog"
      :amountLeftToAllocate="amountLeftToAllocate"
      :tab="tab"
      @closeOverpaidDialog="$set(overpaidDialog, tab, $event)"
      @passOverpaid="overpaidAmount = $event"
    />
  </v-container>
</template>
<script>
import db from "../../../../../components/firebaseInit";
import firebase from "firebase";
import {
  getPaymentCount,
  getJournalEntryCount,
  formatPaymentNumber,
  formatJournalEntryNumber,
  incrementPaymentCount,
  incrementJournalEntryCount,
} from "../../../../Accounting/data/external_invoices";
import { showSnackbar } from "../../../../../globalActions";
export default {
  name: "CustomerPaymentsForm",
  props: ["transaction", "customer"],
  components: {
    CustomerPaymentsInvoiceTable: () =>
      import("./CustomerPaymentsInvoiceTable"),
    CustomerPaymentsProFormaTable: () =>
      import("./CustomerPaymentsProFormaTable"),
    CustomerPaymentTotals: () => import("./CustomerPaymentTotals.vue"),
    AllocationBackBtn: () => import("../AllocationBackBtn.vue"),
    OverpaidModal: () => import("./Overpaid/OverpaidModal.vue"),
  },
  data() {
    return {
      tab: null,
      selectedOrders: null,
      selectedInvoices: null,
      loading: false,
      count: null,
      journal_entry_count: null,
      overpaidDialog: {},
      overpaidAmount: 0,
    };
  },
  computed: {
    //     Disables the "Allocate" button on the form
    returnDisabled() {
      //
      const returnInvoiceState = () => {
        // No invoices selected
        if (!this.selectedInvoices || this.selectedInvoices.length === 0)
          return true;
        //  If the transaction amount is greater than the sum of
        //  all selected invoice totals
        if (this.amountLeftToAllocate !== 0) return true;
        else return false;
      };
      //
      const returnOrderState = () => {
        if (!this.selectedOrders || this.selectedOrders.length === 0)
          return true;
        if (this.amountLeftToAllocate !== 0) return true;
        else return false;
      };

      return this.tab === 0 ? returnInvoiceState() : returnOrderState();
    },
    // Sets the label on the "Allocate" button based on user selection
    returnButtonText() {
      // Invoice tab selection
      const returnInvoiceText = () => {
        if (!this.selectedInvoices || this.selectedInvoices.length <= 1)
          return "Invoice";
        if (this.selectedInvoices && this.selectedInvoices.length > 1)
          return "Invoices";
        else return "Invoices";
      };
      // Pro-forma tab selection
      const returnOrderText = () => {
        if (!this.selectedOrders || this.selectedOrders.length <= 1)
          return "Pro-Forma";
        if (this.selectedOrders && this.selectedOrders.length > 1)
          return "Pro-Formas";
        else return "Pro-Formas";
      };

      return this.tab === 0 ? returnInvoiceText() : returnOrderText();
    },
    //  These properties are related to Customer Overpayments
    //________________________
    totalAmountAllocated() {
      //
      const allocatedToOrders = () => {
        if (!this.selectedOrders) return 0;
        else
          return (
            this.selectedOrders.reduce(
              (total, item) => total + item.allocatedAmount,
              0
            ) + this.overpaidAmount
          );
      };
      //
      const allocatedToInvoices = () => {
        if (!this.selectedInvoices) return 0;
        else
          return (
            this.selectedInvoices.reduce(
              (total, item) => total + item.allocatedAmount,
              0
            ) + this.overpaidAmount
          );
      };
      // Allocate amounts based on ORDER or INVOICE status
      return this.tab === 0 ? allocatedToInvoices() : allocatedToOrders();
    },
    //
    amountLeftToAllocate() {
      return this.transaction.transaction_amount - this.totalAmountAllocated;
    },
    // Toggles the overpaid button if specific conditions are met
    // Toggle for INVOICES && ORDERS tabs
    triggerOverpaid() {
      //
      const triggerForInvoices = () => {
        if (!this.selectedInvoices || this.selectedInvoices.length === 0)
          return false;
        if (
          this.transaction.transaction_amount > this.totalAmountAllocated &&
          this.amountLeftToAllocate !== 0
        )
          return true;
        else return false;
      };
      //
      const triggerForOrders = () => {
        if (!this.selectedOrders || this.selectedOrders.length === 0)
          return false;
        if (
          this.transaction.transaction_amount > this.totalAmountAllocated &&
          this.amountLeftToAllocate !== 0
        )
          return true;
        else return false;
      };
      //
      return this.tab === 0 ? triggerForInvoices() : triggerForOrders();
    },
  },
  watch: {
    //  If the customer changes, clear previously selected items in each table
    customer() {
      this.$refs.CustomerPaymentsProFormaTable.clearSelection();
      this.$refs.CustomerPaymentsInvoiceTable.clearSelection();
    },
    // If the TAB changes remove the overpaid amount
    tab() {
      this.overpaidAmount = 0;
    },
  },
  created() {
    this.getPaymentCount();
    this.getJournalEntryCount();
  },
  methods: {
    getPaymentCount,
    getJournalEntryCount,
    formatPaymentNumber,
    formatJournalEntryNumber,
    incrementPaymentCount,
    incrementJournalEntryCount,
    updateSelection(selectedItems, type) {
      // Add items to selection if their "type" is the currently selected tab
      this[`${type === "order" ? "selectedOrders" : "selectedInvoices"}`] =
        selectedItems;
    },
    // Determines which function to run based on currently selected tab
    allocateCustomerPayment() {
      let type = this.tab === 0 ? "Invoice" : "Pro-Forma";
      type === "Invoice"
        ? this.allocatePaymentToInvoice()
        : this.allocatePaymentToOrder();
    },
    /*
        EXPLANATION:
        -   One customer payment is created
        -   All invoices are linked to payment as an array
        -   The payment is linked to the bank transaction
        -   The invoice is updated (customer payments linked, amounts paid and due updated)
        -   A journal entry is created to update the "Suspense" and "Accounts Receivable"
            ledgers via their contras
        -   Bank transaction is added to the GJE
        -   Update "Accounting Totals" sub-collection for each line-item
        -   Increment GJE && Payment counters
        -   Close modal
        */
    allocatePaymentToInvoice() {
      if (!this.selectedInvoices || this.selectedInvoices.length === 0) return;
      this.loading = true;
      const paymentRef = db.collection("customer_payments").doc();
      const entryRef = db.collection("general_journal_entries").doc();
      const invoiceArray = [];
      const batch = db.batch();
      const paymentObj = {
        payment_id: paymentRef.id,
        payment_number: this.formatPaymentNumber(),
        payment_date: this.transaction.transaction_date,
        payment_amount: this.transaction.transaction_amount,
      };
      // 1) Create payment
      const createPayment = () => {
        // 2) Link invoices to payment
        const populateInvoiceArray = () => {
          this.selectedInvoices.forEach((invoice) => {
            invoiceArray.push({
              invoice_number: invoice.invoice_number,
              invoice_id: invoice.invoice_id,
              invoice_total: invoice.invoice_total,
              allocated_amount: invoice.allocatedAmount,
            });
          });
          return invoiceArray;
        };
        batch.set(paymentRef, {
          ...paymentObj,
          overpaid_amount:
            this.overpaidAmount === 0 ? null : this.overpaidAmount,
          customer: this.customer,
          invoices: populateInvoiceArray(),
          transaction_id: this.transaction.transaction_id,
          transaction_number: this.transaction.transaction_number,
          //  Add journal entry id to payment so that it can be accessed
          //  in the event that the payment is deleted
          entry_id: entryRef.id,
        });
      };
      //  3) Link payment to bank tranaction
      const linkPaymentToTransaction = () => {
        const transactionRef = db.doc(
          `bank_transactions/${this.transaction.transaction_id}`
        );
        batch.update(transactionRef, {
          customer_payment: paymentRef.id,
          transaction_allocation_status: `Customer Payment - ${this.formatPaymentNumber()}`,
        });
      };
      //  4) Update invoice details
      const updateInvioceAmounts = () => {
        this.selectedInvoices.forEach((invoice) => {
          const invoiceRef = db.doc(`invoices/${invoice.invoice_id}`);
          batch.update(invoiceRef, {
            invoice_amount_paid: invoice.invoice_amount_paid,
            invoice_amount_due: invoice.invoice_amount_due,
            customer_payments:
              invoice.customer_payments && invoice.customer_payments.length > 0
                ? invoice.customer_payments.concat([
                    {
                      ...paymentObj,
                      allocated_amount: invoice.allocatedAmount,
                    },
                  ])
                : [
                    {
                      ...paymentObj,
                      allocated_amount: invoice.allocatedAmount,
                    },
                  ],
          });
          // Update related original sales orders
          const salesRef = db.doc(`sales_orders/${invoice.sales_order_id}`);
          batch.update(salesRef, {
            customer_payments:
              invoice.customer_payments && invoice.customer_payments.length > 0
                ? invoice.customer_payments.concat([
                    {
                      ...paymentObj,
                      allocated_amount: invoice.allocatedAmount,
                    },
                  ])
                : [
                    {
                      ...paymentObj,
                      allocated_amount: invoice.allocatedAmount,
                    },
                  ],
            order_amount_paid: invoice.invoice_amount_paid,
            order_amount_due: invoice.invoice_amount_due,
          });
        });
      };
      //    5) Create Journal Entry
      const createJournalEntry = () => {
        // determine line items
        const populateLineItems = () => {
          const lineItems = [];
          // Increase "Suspense" Contra
          const suspenseContra = {
            entry_amount: this.transaction.transaction_amount,
            line_amount: this.transaction.transaction_amount,
            ledger_account: {
              account_id: "GHxChc2AGkXMiQnSvqNC",
              account_name: "Suspense Contra (Bank Credits)",
            },
          };
          // Increase "Customer Down Payments"
          const downPayments = {
            entry_amount: this.transaction.transaction_amount,
            line_amount: this.transaction.transaction_amount,
            ledger_account: {
              account_id: "GV7ECGDUiHUchX9iA4qw",
              account_name: "Customer Down Payments",
            },
          };
          // Increase overpaid amount
          const customerOverpaid = {
            entry_amount: this.overpaidAmount,
            line_amount: this.overpaidAmount,
            ledger_account: {
              account_id: "r0q5ogDJ76WcU4vGGrSb",
              account_name: "Customer Overpayments",
            },
          };
          lineItems.push(suspenseContra, downPayments);
          if (this.overpaidAmount !== 0) {
            lineItems.push(customerOverpaid);
          }
          return lineItems;
        };
        batch.set(entryRef, {
          journal_entry_id: entryRef.id,
          journal_entry_date: this.transaction.transaction_date,
          journal_entry_number: this.formatJournalEntryNumber(),
          closed_off: false,
          //  Add payment details so that GJE can be deleted if payment is deleted
          customer_payment: {
            payment_id: paymentRef.id,
            payment_number: this.formatPaymentNumber(),
          },
          bank_transaction: {
            transaction_id: this.transaction.transaction_id,
            transaction_number: this.transaction.transaction_number,
          },
          line_items: populateLineItems(),
        });
      };
      // 6) Update "Accounting Totals"
      const updateAccountingTotals = () => {
        // "Suspense Contra" and "Accounts Receivable Contra" ids
        const accountsToUpdate = [
          "GHxChc2AGkXMiQnSvqNC",
          "HXGIZu7HoqPCgVev56Ee",
        ];
        accountsToUpdate.forEach((accountID) => {
          const accountRef = db
            .collection("accounting_totals")
            .doc(this.transaction.transaction_date.slice(0, 7))
            .collection("ledger_accounts")
            .doc(accountID);
          batch.update(accountRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              this.transaction.transaction_amount
            ),
          });
        });
      };
      // Update overpaid amount in Accounting Totals
      const updateOverpaidLedger = () => {
        if (this.overpaid === 0) return;
        const ledgerRef = db.doc(
          `accounting_totals/${this.transaction.transaction_date.slice(
            0,
            7
          )}/ledger_accounts/r0q5ogDJ76WcU4vGGrSb`
        );
        batch.update(ledgerRef, {
          monthly_total: firebase.firestore.FieldValue.increment(
            this.overpaidAmount
          ),
        });
      };
      // 7) Close the allocation modal
      createPayment();
      linkPaymentToTransaction();
      updateInvioceAmounts();
      createJournalEntry();
      updateAccountingTotals();
      updateOverpaidLedger();
      // Commit batch
      batch
        .commit()
        .then(() => {
          //    Increment CPA # and Journal Entry # count
          this.loading = false;
          this.count++;
          this.journal_entry_count++;
          this.incrementPaymentCount();
          this.incrementJournalEntryCount();
          showSnackbar(
            `Customer payment allocted to ${
              this.selectedInvoices.length === 1 ? "invoice" : "Invoices"
            }`
          );
          // 7) Close the allocation modal
        })
        .catch((error) => error);
    },
    //________________________________________________________________________________
    //
    allocatePaymentToOrder() {
      if (!this.selectedOrders || this.selectedOrders.length === 0) return;
      this.loading = true;
      const paymentRef = db.collection("customer_payments").doc();
      const entryRef = db.collection("general_journal_entries").doc();
      const orderArray = [];
      const batch = db.batch();
      const paymentObj = {
        payment_id: paymentRef.id,
        payment_number: this.formatPaymentNumber(),
        payment_date: this.transaction.transaction_date,
        payment_amount: this.transaction.transaction_amount,
      };
      // 1) Create payment
      const createPayment = () => {
        // 2) Link order to payment
        const populateOrderArray = () => {
          this.selectedOrders.forEach((order) => {
            orderArray.push({
              order_number: order.order_number,
              order_id: order.order_id,
              order_value: order.order_value,
              allocated_amount: order.allocatedAmount,
            });
          });
          return orderArray;
        };
        batch.set(paymentRef, {
          ...paymentObj,
          customer: this.customer,
          sales_orders: populateOrderArray(),
          transaction_id: this.transaction.transaction_id,
          transaction_number: this.transaction.transaction_number,
          //  Add journal entry id to payment so that it can be accessed
          //  in the event that the payment is deleted
          entry_id: entryRef.id,
        });
      };
      //  3) Link payment to bank tranaction
      const linkPaymentToTransaction = () => {
        const transactionRef = db.doc(
          `bank_transactions/${this.transaction.transaction_id}`
        );
        batch.update(transactionRef, {
          customer_payment: paymentRef.id,
          transaction_allocation_status: `Customer Payment - ${this.formatPaymentNumber()}`,
        });
      };
      //  4) Update order details
      const updateOrderAmounts = () => {
        this.selectedOrders.forEach((order) => {
          const orderRef = db.doc(`sales_orders/${order.order_id}`);
          batch.update(orderRef, {
            order_amount_paid: order.order_amount_paid,
            order_amount_due: order.order_value - order.order_amount_paid,
            customer_payments:
              order.customer_payments && order.customer_payments.length > 0
                ? order.customer_payments.concat([
                    { ...paymentObj, allocated_amount: order.allocatedAmount },
                  ])
                : [{ ...paymentObj, allocated_amount: order.allocatedAmount }],
          });
        });
      };
      //    5) Create Journal Entry
      const createJournalEntry = () => {
        // determine line items
        const populateLineItems = () => {
          const lineItems = [];
          // Increase "Suspense" Contra
          const suspenseContra = {
            entry_amount: this.transaction.transaction_amount,
            line_amount: this.transaction.transaction_amount,
            ledger_account: {
              account_id: "GHxChc2AGkXMiQnSvqNC",
              account_name: "Suspense Contra (Bank Credits)",
            },
          };
          // Increase "Customer Down Payments"
          const downPayments = {
            entry_amount: this.transaction.transaction_amount,
            line_amount: this.transaction.transaction_amount,
            ledger_account: {
              account_id: "GV7ECGDUiHUchX9iA4qw",
              account_name: "Customer Down Payments",
            },
          };
          // Increase overpaid amount
          const customerOverpaid = {
            entry_amount: this.overpaidAmount,
            line_amount: this.overpaidAmount,
            ledger_account: {
              account_id: "r0q5ogDJ76WcU4vGGrSb",
              account_name: "Customer Overpayments",
            },
          };
          lineItems.push(suspenseContra, downPayments);
          if (this.overpaidAmount !== 0) {
            lineItems.push(customerOverpaid);
          }
          return lineItems;
        };
        batch.set(entryRef, {
          journal_entry_id: entryRef.id,
          journal_entry_date: this.transaction.transaction_date,
          journal_entry_number: this.formatJournalEntryNumber(),
          closed_off: false,
          //  Add payment details so that GJE can be deleted if payment is deleted
          customer_payment: {
            payment_id: paymentRef.id,
            payment_number: this.formatPaymentNumber(),
          },
          bank_transaction: {
            transaction_id: this.transaction.transaction_id,
            transaction_number: this.transaction.transaction_number,
          },
          line_items: populateLineItems(),
        });
      };
      // 6) Update "Accounting Totals"
      const updateAccountingTotals = () => {
        // "Suspense Contra" and "Customer Down Payments" ids
        const accountsToUpdate = [
          "GHxChc2AGkXMiQnSvqNC",
          "GV7ECGDUiHUchX9iA4qw",
        ];
        accountsToUpdate.forEach((accountID) => {
          const accountRef = db
            .collection("accounting_totals")
            .doc(this.transaction.transaction_date.slice(0, 7))
            .collection("ledger_accounts")
            .doc(accountID);
          batch.update(accountRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              this.transaction.transaction_amount
            ),
          });
        });
      };
      // Update overpaid amount in Accounting Totals
      const updateOverpaidLedger = () => {
        if (this.overpaid === 0) return;
        const ledgerRef = db.doc(
          `accounting_totals/${this.transaction.transaction_date.slice(
            0,
            7
          )}/ledger_accounts/r0q5ogDJ76WcU4vGGrSb`
        );
        batch.update(ledgerRef, {
          monthly_total: firebase.firestore.FieldValue.increment(
            this.overpaidAmount
          ),
        });
      };
      // 7) Close the allocation modal
      createPayment();
      linkPaymentToTransaction();
      updateOrderAmounts();
      createJournalEntry();
      updateAccountingTotals();
      updateOverpaidLedger();
      // Commit batch
      batch
        .commit()
        .then(() => {
          //    Increment CPA # and Journal Entry # count
          this.loading = false;
          this.count++;
          this.journal_entry_count++;
          this.incrementPaymentCount();
          this.incrementJournalEntryCount();
          showSnackbar(
            `Customer payment allocted to ${
              this.selectedOrders.length === 1 ? "Pro-Forma" : "Pro-Formas"
            }`
          );
          // 7) Close the allocation modal
        })
        .catch((error) => error);
    },
  },
};
</script>